import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { useAppDispatch, useAppSelector } from 'app/store';
import {
  fetchInventoryRequests,
  fetchStoreRequestCount,
  selectInventoryShopLoading,
  toggleShopLoading,
} from 'app/store/inventoryRequests';
import FormRequestBlock from 'components/FormRequestBlock';
import { getRequestShopByURLInput, REQUEST_STORE_BY_URL } from 'app/graphql/cart-api';
import { INPUT_PATTERN, trackSegmentEvent } from 'app/utils';
import { ACTION, SOURCE } from 'app/analytics';

const DEFAULT_INPUT_VALUE = '';

const StoreUrlRequest = () => {
  const dispatch = useAppDispatch();
  const formLoading = useAppSelector(selectInventoryShopLoading);

  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUE);
  const [storeRequestError, setStoreRequestError] = useState<string>('');

  const [mutateFunction] = useMutation(REQUEST_STORE_BY_URL);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const variables = getRequestShopByURLInput(inputValue);

    if (variables) {
      dispatch(toggleShopLoading(true));

      // Actually send the request
      try {
        await mutateFunction({ variables });
      } catch (e: unknown) {
        if (e instanceof Error) {
          // Show a specific error for when users reach their limit
          if (e.message.includes('requestStoreByURL limit reached')) {
            // Use local state because redux is overkill for this
            setStoreRequestError(
              "You've reached your limit for requesting stores. Please reach out to dev@rye.com for more information and to increase your limit.",
            );
          } else if (e.message.includes('Store has already been added to Rye')) {
            setStoreRequestError(
              'This store has already been added to Rye and cannot be added again.',
            );
          } else {
            setStoreRequestError(
              'Something went wrong, please try again. Please reach out to dev@rye.com if the issue persists.',
            );
          }
        }
      }

      dispatch(fetchInventoryRequests());
      dispatch(toggleShopLoading(false));
      dispatch(fetchStoreRequestCount());

      setInputValue(DEFAULT_INPUT_VALUE);
    } else {
      // @TODO add notification UI
      alert('Please provide correct product URL');
    }
    trackSegmentEvent(SOURCE.REQUEST_STORE_URL_MODULE, ACTION.CLICK, 'request_button', {
      store_url: inputValue,
    });
  };

  return (
    <FormRequestBlock
      title="Add store URL"
      submitValue="Request"
      placeholder="Enter store URL"
      text={<p>Enter a store URL that you want to request access for</p>}
      loading={formLoading}
      required={true}
      type="url"
      pattern={INPUT_PATTERN.URL}
      inputValue={inputValue}
      onChange={setInputValue}
      onSubmit={handleSubmit}
      error={storeRequestError}
    />
  );
};

export default StoreUrlRequest;
