import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutside = (ref: React.RefObject<HTMLElement>, cb: () => void) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const isClickOutside = !ref?.current?.contains(e.target as Node);
      isClickOutside && cb();
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb]);
};

export default useOutside;
