import UnreadCounter from 'components/UnreadCounter';

import './style.scss';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';

const Ava = () => {
  const userInfo = useAppSelector(selectUserInfo);

  if (userInfo) {
    const letter = userInfo.displayName || userInfo.email;
    const ltr = letter?.charAt(0).toUpperCase();
    return (
      <div className="avatar">
        <UnreadCounter />
        {ltr}
      </div>
    );
  }
  return null;
};

export default Ava;
