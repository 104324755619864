import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Container, TabContainer } from './styles';
import { PaymentDialog } from './components/PaymentDialog';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAppDispatch } from 'app/store';
import { fetchUserInfo } from 'app/store/user';
import useAuth from 'hooks/useAuth';
import * as Sentry from '@sentry/react';
import { env } from 'config/env';
import useTheme from 'hooks/useTheme';
import { BillingActivityTabContent } from './components/BillingActivityTabContent';
import { BillingInvoiceTabContent } from './components/BillingInvoiceTabContent';

const stripePromise = loadStripe(env.REACT_APP_STRIPE_KEY);
const Page = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { theme } = useTheme();

  const handlePaymentSubmit = () => {
    if (!user) {
      Sentry.captureException('Payment submission failed - Not Authenticated');
      return;
    }
    dispatch(fetchUserInfo(user?.uid, user));
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'payment',
        amount: 100,
        currency: 'usd',
        paymentMethodCreation: 'manual',
        appearance: {
          theme: theme === 'dark' ? 'night' : 'flat',
          labels: 'floating',
          variables: { colorPrimary: 'rgb(78, 213, 88)', borderRadius: '12px' },
        },
      }}
    >
      <Container>
        <Tabs defaultValue="billing-activity" className="w-[100%]">
          <TabContainer>
            <TabsList>
              <TabsTrigger value="billing-activity">Billing Activity</TabsTrigger>
              <TabsTrigger value="invoices">Invoices</TabsTrigger>
            </TabsList>
            <PaymentDialog onSubmit={handlePaymentSubmit} />
          </TabContainer>

          <BillingActivityTabContent value="billing-activity" />
          <BillingInvoiceTabContent value="invoices" />
        </Tabs>
      </Container>
    </Elements>
  );
};

export default Page;
