import React, { useEffect, useMemo, useState } from 'react';

import config from 'config/constants';
import FormRequestBlock from 'components/FormRequestBlock';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { INPUT_PATTERN, trackSegmentEvent } from 'app/utils';
import { ACTION, SOURCE } from 'app/analytics';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { SAVE_WEBHOOK_URL } from 'app/graphql';
import { useMutation } from '@apollo/client/react';

const WebhooksUrlBlock = () => {
  const {
    webhookURL = '',
    webhookURLVerified = false,
    hmacSecretKey,
  } = useAppSelector(selectUserInfo) || {};

  const [inputValue, setInputValue] = useState(webhookURL);
  const [justCopied, setJustCopied] = useState<boolean>(false);

  const [saveWebhookUrl, saveWebhookUrlInfo] = useMutation(SAVE_WEBHOOK_URL, {
    context: { clientName: 'admin' },
  });

  const verified = useMemo(() => {
    // webhook was changed. Reset the verification status
    if (inputValue !== webhookURL && !saveWebhookUrlInfo.data) return false;

    if (saveWebhookUrlInfo.error) return false;

    return saveWebhookUrlInfo.data?.saveWebhookURL?.verified ?? webhookURLVerified;
  }, [saveWebhookUrlInfo, webhookURLVerified, inputValue, webhookURL]);

  useEffect(() => {
    setInputValue(webhookURL);
  }, [webhookURL]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    trackSegmentEvent(SOURCE.WEBHOOK_MODULE, ACTION.CLICK, 'update_button');
    saveWebhookUrl({
      variables: {
        input: {
          url: inputValue,
        },
      },
    });
  };

  function handleCopyClick(e: React.SyntheticEvent) {
    e.preventDefault();

    if (hmacSecretKey) {
      navigator.clipboard.writeText(hmacSecretKey);

      // Change the copy icon to a checkmark for ~ 1s
      setJustCopied(true);
      setTimeout(() => setJustCopied(false), 1000);
    }
  }

  const VerifiedStatusComponent = useMemo(() => {
    return verified ? (
      <div className="contrast flex flex-row items-center">
        Verified
        <CheckIcon height={16} className="ml-1.5 fill-[var(--contrast)]" />
      </div>
    ) : undefined;
  }, [verified]);

  const ErrorStatusComponent = useMemo(() => {
    return saveWebhookUrlInfo.error ? (
      <p className="flex flex-row items-center text-amber-300">
        Your URL didn't respond with the value of the <b className="mx-1">challenge</b> parameter.
      </p>
    ) : undefined;
  }, [saveWebhookUrlInfo.error]);

  return (
    <FormRequestBlock
      title="Add an endpoint for receiving webhooks"
      placeholder="Enter a URL for webhooks"
      statusDetail={verified ? VerifiedStatusComponent : ErrorStatusComponent}
      onSubmit={handleSubmit}
      loading={saveWebhookUrlInfo.loading}
      required={true}
      type="url"
      pattern={INPUT_PATTERN.URL}
      inputValue={inputValue}
      onChange={setInputValue}
      text={
        <>
          <p>Webhook events will be sent via HTTP POST requests.</p>
          <p>
            As soon as you click save, we'll send a request with a <b>challenge</b> parameter, and
            your endpoint must respond with the challenge value in order to save this form.{' '}
            <a href={config.external.doc.webhookUpdate} target="_blank" rel="noreferrer">
              View the docs
            </a>{' '}
            to learn more, including what webhook types are available, and how to validate webhook
            requests
          </p>
          <div className="copyable-secret">
            <p>Your HMAC secret key for webhook validation: </p>

            <div className="copyable-secret__value" onClick={handleCopyClick}>
              {hmacSecretKey}{' '}
              {justCopied ? (
                <CheckIcon height={16} className="copyable-secret__icon" />
              ) : (
                <CopyIcon height={16} className="copyable-secret__icon" />
              )}
            </div>
          </div>
        </>
      }
    />
  );
};

export default WebhooksUrlBlock;
