/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation attachPaymentMethod($input: AttachPaymentMethodInput!) {\n    attachPaymentMethod(input: $input) {\n      stripeConfig {\n        customerId\n        paymentMethod {\n          ... on PaymentMethodCard {\n            last4\n            brand\n            expMonth\n            expYear\n          }\n          ... on PaymentMethodACH {\n            last4AccountNumber\n            bank\n          }\n        }\n      }\n    }\n  }\n':
    types.AttachPaymentMethodDocument,
  '\n  mutation saveWebhookURL($input: SaveWebhookURLInput!) {\n    saveWebhookURL(input: $input) {\n      url\n      verified\n    }\n  }\n':
    types.SaveWebhookUrlDocument,
  '\n  query merchantsByDeveloper {\n    merchantsByDeveloper {\n      merchants {\n        commissionRate\n        commissionType\n        domain\n        logo\n        name\n      }\n    }\n  }\n':
    types.MerchantsByDeveloperDocument,
  '\n  query BillingItems($pagination: BillingItemsPaginationInput, $filters: BillingFiltersInput) {\n    billingItems(pagination: $pagination, filters: $filters) {\n      edges {\n        node {\n          createdAt\n          id\n          orderId\n          amount {\n            currency\n            value\n          }\n          type\n          payer\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n        totalPages\n      }\n    }\n  }\n':
    types.BillingItemsDocument,
  '\n  query invoices($filters: InvoiceFiltersInput) {\n    invoices(filters: $filters) {\n      edges {\n        cursor\n        node {\n          createdAt\n          amount {\n            value\n            currency\n          }\n          billingPeriod {\n            startDate\n            endDate\n          }\n          dueDate\n          status\n          items {\n            description\n            amount {\n              value\n              currency\n            }\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n':
    types.InvoicesDocument,
  '\n  query OrderById($id: ID!) {\n    orderById(id: $id) {\n      marketplaceOrderIds\n      breakdown {\n        subtotalCents\n        totalCents\n        taxCents\n        discountCents\n        currency\n        shippingCents\n      }\n      marketplace\n      commission {\n        rate\n        status\n      }\n      products {\n        productId\n        quantity\n        ... on ShopifyProduct {\n          variantId\n        }\n      }\n    }\n  }\n':
    types.OrderByIdDocument,
  '\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      data {\n        user {\n          firstName\n          lastName\n          displayName\n          apiKey\n          adminToken\n          authProvider\n          email\n          id\n          webhookURL\n          webhookURLVerified\n          marginAmount\n          marginIsPercent\n          photoURL\n          isApprovedForDirectCheckout\n          spreedly {\n            envToken\n            envSecret\n          }\n          amazonBusinessConfig {\n            businessType\n            groupId\n            email\n            pendingGroupAccess\n            token {\n              type\n              status\n            }\n          }\n          website\n          inventoryRequestLimits {\n            uniqueStoreRequests\n            uniqueProductRequests\n          }\n          jwtValidationKey\n          hmacSecretKey\n        }\n        billingPeriodSettings {\n          ... on BillingPerPeriod {\n            gracePeriodDays\n            billingPeriodDays\n            billingPeriodStartDay\n          }\n          ... on BillingPerGMV {\n            amount\n            currency\n            gracePeriodDays\n          }\n        }\n        stripeConfig {\n          customerId\n          paymentMethod {\n            ... on PaymentMethodCard {\n              last4\n              brand\n              expMonth\n              expYear\n            }\n            ... on PaymentMethodACH {\n              last4AccountNumber\n              bank\n            }\n          }\n        }\n      }\n      error\n    }\n  }\n':
    types.UpdateUserDocument,
  '\n  query user {\n    user {\n      data {\n        user {\n          firstName\n          lastName\n          displayName\n          apiKey\n          adminToken\n          authProvider\n          email\n          id\n          webhookURL\n          webhookURLVerified\n          marginAmount\n          marginIsPercent\n          photoURL\n          isApprovedForDirectCheckout\n          spreedly {\n            envToken\n            envSecret\n          }\n          amazonBusinessConfig {\n            businessType\n            groupId\n            email\n            pendingGroupAccess\n            token {\n              type\n              status\n            }\n          }\n          website\n          inventoryRequestLimits {\n            uniqueStoreRequests\n            uniqueProductRequests\n          }\n          jwtValidationKey\n          hmacSecretKey\n        }\n        billingPeriodSettings {\n          ... on BillingPerPeriod {\n            gracePeriodDays\n            billingPeriodDays\n            billingPeriodStartDay\n          }\n          ... on BillingPerGMV {\n            amount\n            currency\n            gracePeriodDays\n          }\n        }\n        stripeConfig {\n          customerId\n          paymentMethod {\n            ... on PaymentMethodCard {\n              last4\n              brand\n              expMonth\n              expYear\n            }\n            ... on PaymentMethodACH {\n              last4AccountNumber\n              bank\n            }\n          }\n        }\n      }\n      error\n    }\n  }\n':
    types.UserDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation attachPaymentMethod($input: AttachPaymentMethodInput!) {\n    attachPaymentMethod(input: $input) {\n      stripeConfig {\n        customerId\n        paymentMethod {\n          ... on PaymentMethodCard {\n            last4\n            brand\n            expMonth\n            expYear\n          }\n          ... on PaymentMethodACH {\n            last4AccountNumber\n            bank\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation attachPaymentMethod($input: AttachPaymentMethodInput!) {\n    attachPaymentMethod(input: $input) {\n      stripeConfig {\n        customerId\n        paymentMethod {\n          ... on PaymentMethodCard {\n            last4\n            brand\n            expMonth\n            expYear\n          }\n          ... on PaymentMethodACH {\n            last4AccountNumber\n            bank\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation saveWebhookURL($input: SaveWebhookURLInput!) {\n    saveWebhookURL(input: $input) {\n      url\n      verified\n    }\n  }\n',
): (typeof documents)['\n  mutation saveWebhookURL($input: SaveWebhookURLInput!) {\n    saveWebhookURL(input: $input) {\n      url\n      verified\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query merchantsByDeveloper {\n    merchantsByDeveloper {\n      merchants {\n        commissionRate\n        commissionType\n        domain\n        logo\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query merchantsByDeveloper {\n    merchantsByDeveloper {\n      merchants {\n        commissionRate\n        commissionType\n        domain\n        logo\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BillingItems($pagination: BillingItemsPaginationInput, $filters: BillingFiltersInput) {\n    billingItems(pagination: $pagination, filters: $filters) {\n      edges {\n        node {\n          createdAt\n          id\n          orderId\n          amount {\n            currency\n            value\n          }\n          type\n          payer\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n        totalPages\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BillingItems($pagination: BillingItemsPaginationInput, $filters: BillingFiltersInput) {\n    billingItems(pagination: $pagination, filters: $filters) {\n      edges {\n        node {\n          createdAt\n          id\n          orderId\n          amount {\n            currency\n            value\n          }\n          type\n          payer\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n        totalPages\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query invoices($filters: InvoiceFiltersInput) {\n    invoices(filters: $filters) {\n      edges {\n        cursor\n        node {\n          createdAt\n          amount {\n            value\n            currency\n          }\n          billingPeriod {\n            startDate\n            endDate\n          }\n          dueDate\n          status\n          items {\n            description\n            amount {\n              value\n              currency\n            }\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n',
): (typeof documents)['\n  query invoices($filters: InvoiceFiltersInput) {\n    invoices(filters: $filters) {\n      edges {\n        cursor\n        node {\n          createdAt\n          amount {\n            value\n            currency\n          }\n          billingPeriod {\n            startDate\n            endDate\n          }\n          dueDate\n          status\n          items {\n            description\n            amount {\n              value\n              currency\n            }\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OrderById($id: ID!) {\n    orderById(id: $id) {\n      marketplaceOrderIds\n      breakdown {\n        subtotalCents\n        totalCents\n        taxCents\n        discountCents\n        currency\n        shippingCents\n      }\n      marketplace\n      commission {\n        rate\n        status\n      }\n      products {\n        productId\n        quantity\n        ... on ShopifyProduct {\n          variantId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query OrderById($id: ID!) {\n    orderById(id: $id) {\n      marketplaceOrderIds\n      breakdown {\n        subtotalCents\n        totalCents\n        taxCents\n        discountCents\n        currency\n        shippingCents\n      }\n      marketplace\n      commission {\n        rate\n        status\n      }\n      products {\n        productId\n        quantity\n        ... on ShopifyProduct {\n          variantId\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      data {\n        user {\n          firstName\n          lastName\n          displayName\n          apiKey\n          adminToken\n          authProvider\n          email\n          id\n          webhookURL\n          webhookURLVerified\n          marginAmount\n          marginIsPercent\n          photoURL\n          isApprovedForDirectCheckout\n          spreedly {\n            envToken\n            envSecret\n          }\n          amazonBusinessConfig {\n            businessType\n            groupId\n            email\n            pendingGroupAccess\n            token {\n              type\n              status\n            }\n          }\n          website\n          inventoryRequestLimits {\n            uniqueStoreRequests\n            uniqueProductRequests\n          }\n          jwtValidationKey\n          hmacSecretKey\n        }\n        billingPeriodSettings {\n          ... on BillingPerPeriod {\n            gracePeriodDays\n            billingPeriodDays\n            billingPeriodStartDay\n          }\n          ... on BillingPerGMV {\n            amount\n            currency\n            gracePeriodDays\n          }\n        }\n        stripeConfig {\n          customerId\n          paymentMethod {\n            ... on PaymentMethodCard {\n              last4\n              brand\n              expMonth\n              expYear\n            }\n            ... on PaymentMethodACH {\n              last4AccountNumber\n              bank\n            }\n          }\n        }\n      }\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      data {\n        user {\n          firstName\n          lastName\n          displayName\n          apiKey\n          adminToken\n          authProvider\n          email\n          id\n          webhookURL\n          webhookURLVerified\n          marginAmount\n          marginIsPercent\n          photoURL\n          isApprovedForDirectCheckout\n          spreedly {\n            envToken\n            envSecret\n          }\n          amazonBusinessConfig {\n            businessType\n            groupId\n            email\n            pendingGroupAccess\n            token {\n              type\n              status\n            }\n          }\n          website\n          inventoryRequestLimits {\n            uniqueStoreRequests\n            uniqueProductRequests\n          }\n          jwtValidationKey\n          hmacSecretKey\n        }\n        billingPeriodSettings {\n          ... on BillingPerPeriod {\n            gracePeriodDays\n            billingPeriodDays\n            billingPeriodStartDay\n          }\n          ... on BillingPerGMV {\n            amount\n            currency\n            gracePeriodDays\n          }\n        }\n        stripeConfig {\n          customerId\n          paymentMethod {\n            ... on PaymentMethodCard {\n              last4\n              brand\n              expMonth\n              expYear\n            }\n            ... on PaymentMethodACH {\n              last4AccountNumber\n              bank\n            }\n          }\n        }\n      }\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query user {\n    user {\n      data {\n        user {\n          firstName\n          lastName\n          displayName\n          apiKey\n          adminToken\n          authProvider\n          email\n          id\n          webhookURL\n          webhookURLVerified\n          marginAmount\n          marginIsPercent\n          photoURL\n          isApprovedForDirectCheckout\n          spreedly {\n            envToken\n            envSecret\n          }\n          amazonBusinessConfig {\n            businessType\n            groupId\n            email\n            pendingGroupAccess\n            token {\n              type\n              status\n            }\n          }\n          website\n          inventoryRequestLimits {\n            uniqueStoreRequests\n            uniqueProductRequests\n          }\n          jwtValidationKey\n          hmacSecretKey\n        }\n        billingPeriodSettings {\n          ... on BillingPerPeriod {\n            gracePeriodDays\n            billingPeriodDays\n            billingPeriodStartDay\n          }\n          ... on BillingPerGMV {\n            amount\n            currency\n            gracePeriodDays\n          }\n        }\n        stripeConfig {\n          customerId\n          paymentMethod {\n            ... on PaymentMethodCard {\n              last4\n              brand\n              expMonth\n              expYear\n            }\n            ... on PaymentMethodACH {\n              last4AccountNumber\n              bank\n            }\n          }\n        }\n      }\n      error\n    }\n  }\n',
): (typeof documents)['\n  query user {\n    user {\n      data {\n        user {\n          firstName\n          lastName\n          displayName\n          apiKey\n          adminToken\n          authProvider\n          email\n          id\n          webhookURL\n          webhookURLVerified\n          marginAmount\n          marginIsPercent\n          photoURL\n          isApprovedForDirectCheckout\n          spreedly {\n            envToken\n            envSecret\n          }\n          amazonBusinessConfig {\n            businessType\n            groupId\n            email\n            pendingGroupAccess\n            token {\n              type\n              status\n            }\n          }\n          website\n          inventoryRequestLimits {\n            uniqueStoreRequests\n            uniqueProductRequests\n          }\n          jwtValidationKey\n          hmacSecretKey\n        }\n        billingPeriodSettings {\n          ... on BillingPerPeriod {\n            gracePeriodDays\n            billingPeriodDays\n            billingPeriodStartDay\n          }\n          ... on BillingPerGMV {\n            amount\n            currency\n            gracePeriodDays\n          }\n        }\n        stripeConfig {\n          customerId\n          paymentMethod {\n            ... on PaymentMethodCard {\n              last4\n              brand\n              expMonth\n              expYear\n            }\n            ... on PaymentMethodACH {\n              last4AccountNumber\n              bank\n            }\n          }\n        }\n      }\n      error\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
