import { useEffect, useRef, useState } from 'react';

import FormRequestBlock from 'components/FormRequestBlock';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserInfo, selectUserInfoLoading } from 'app/store/user';

import './style.scss';
import { usersCollection } from 'app/firebase/collections/users';
import { useSearchParams } from 'react-router-dom';
import { checkAmazonAuth, ryeOAuth } from 'app/amazon/auth';
import { AMAZON_AUTH_URL } from 'app/amazon/constants';
import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';
import { toast } from 'react-toastify';
import { Button, Tooltip } from 'antd';
import { AmazonBusinessAccount } from 'app/firebase/collections/amazonBusinessAccounts';
import {
  fetchAmazonBusinessAccounts,
  selectAmazonBusinessAccounts,
} from 'app/store/amazonBusinessAccounts';
import { DeleteOutlined } from '@ant-design/icons';
import {
  AmazonBusinessConfig,
  AmazonToken,
  AmazonTokenStatus,
} from 'app/graphql/generated/admin/graphql';
import { ADMIN_USER } from 'app/graphql';
import { useQuery } from '@apollo/client/react';

const AmazonBusiness = ({
  amazonBusinessConfig,
  userId,
}: {
  amazonBusinessConfig: AmazonBusinessConfig;
  userId: string;
}) => {
  const dispatch = useAppDispatch();

  const isUserInfoLoading = useAppSelector(selectUserInfoLoading);
  const { adminToken } = useAppSelector(selectUserInfo) || {};

  const [formLoading, setFormLoading] = useState(!amazonBusinessConfig.token);
  const [isRevoking, setRevoking] = useState(false);
  const [inputValue, setInputValue] = useState(amazonBusinessConfig.groupId ?? '');
  const [amazonEmail, setAmazonEmail] = useState(amazonBusinessConfig.email ?? '');
  const [credentials, setCredentials] = useState<AmazonToken | undefined | null>(
    amazonBusinessConfig.token,
  );
  const codeSet = useRef(false);
  const checkingStatus = useRef(false);
  const authInterval = useRef<ReturnType<typeof setInterval>>();
  const loadingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const additionalAmazonBusinessAccounts = useAppSelector(selectAmazonBusinessAccounts);

  const [additionalAccounts, setAdditionalAccounts] = useState<AmazonBusinessAccount[]>(
    additionalAmazonBusinessAccounts,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const { data, refetch } = useQuery(ADMIN_USER, {
    skip: !adminToken,
    context: {
      clientName: 'admin',
    },
  });

  const authenticate = async (code: string) => {
    await ryeOAuth(code, userId);
    setCredentials(data?.user?.data?.user?.amazonBusinessConfig?.token);
  };

  const checkAuthStatus = async (showAuthRevokedMessage?: boolean) => {
    checkingStatus.current = true;
    const data = await refetch();
    const res = await checkAmazonAuth(userId);
    const user = data?.data.user.data?.user;

    if (res.revoked === 'REVOKED' && user?.amazonBusinessConfig?.token?.status === 'ACTIVE') {
      await usersCollection.updateAmazonBusinessConfig(userId, {
        ...user?.amazonBusinessConfig,
        groupId: '',
        email: '',
        token: { ...user?.amazonBusinessConfig.token, status: AmazonTokenStatus.Revoked },
      });

      clearInterval(authInterval.current);
      authInterval.current = undefined;
      setCredentials(undefined);
      setRevoking(false);
      setFormLoading(false);
      if (showAuthRevokedMessage) {
        toast.error(
          'Your Amazon Business account is no longer authenticated. Please authenticate again.',
          {
            position: 'bottom-right',
            autoClose: 8000,
          },
        );
      }

      checkingStatus.current = false;
    }
  };

  const handleRevoke = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(AMAZON_AUTH_URL, '_blank');
    setRevoking(true);
    setFormLoading(true);
    authInterval.current = setInterval(async () => {
      try {
        await checkAuthStatus();
      } catch {
        // catch different error codes
      }
    }, 1000);

    // after 30 seconds stop polling to check if auth is revoked
    loadingTimeout.current = setTimeout(() => {
      clearInterval(authInterval.current);
      authInterval.current = undefined;
      setFormLoading(false);
    }, 1000 * 30);
  };

  const handleGroupIdSave = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setFormLoading(true);
    const data = await refetch();
    const groupId = inputValue.trim();
    const email = amazonEmail.trim();

    const user = data?.data.user.data?.user;

    if (groupId && email && user?.amazonBusinessConfig) {
      await usersCollection.updateAmazonBusinessConfig(userId, {
        ...user?.amazonBusinessConfig,
        groupId,
        email,
      });
    }
    loadingTimeout.current = setTimeout(() => {
      setFormLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const code = searchParams.get('code');
    if (code && !codeSet.current) {
      codeSet.current = true;
      setSearchParams();
      authenticate(code);
    }

    return () => {
      clearInterval(authInterval.current);
      clearTimeout(loadingTimeout.current);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAmazonBusinessAccounts(userId));
    setAdditionalAccounts(additionalAmazonBusinessAccounts);

    setFormLoading(!amazonBusinessConfig && isUserInfoLoading);
  }, [amazonBusinessConfig, isUserInfoLoading]);

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code && !checkingStatus.current) {
      checkAuthStatus(true);
    }
  }, []);

  return (
    <FormRequestBlock
      styles={{ form: { flexDirection: 'column', gap: '10px' } }}
      title={'Amazon Access'}
      text={
        <>
          {!credentials ? (
            'Authenticate Rye API to place orders with your Amazon Business Account'
          ) : (
            <>Below is a list of your Amazon accounts Rye uses to place orders.</>
          )}
        </>
      }
      loading={formLoading}
    >
      <div className="amazon-business-form">
        {credentials?.status !== 'ACTIVE' ? (
          <a className="amazon-business-oauth-authenticate" href={AMAZON_AUTH_URL}>
            Authenticate
          </a>
        ) : (
          <>
            {!amazonBusinessConfig.groupId && !amazonBusinessConfig.email && (
              <div className="amazon-business-body">
                <FormInput
                  id="amazon-business-input"
                  className="amazon-business-input"
                  type="text"
                  defaultValue={inputValue}
                  placeholder="GroupId"
                  onChange={(value) => setInputValue(value)}
                />
                <FormInput
                  id="amazon-business-input-email"
                  className="amazon-business-input"
                  type="text"
                  defaultValue={amazonEmail}
                  placeholder="Email"
                  onChange={(value) => setAmazonEmail(value)}
                />

                <FormButton disabled={formLoading} onClick={handleGroupIdSave}>
                  Save
                </FormButton>
                <FormButton
                  className={`amazon-business-oauth ${isRevoking ? 'revoking' : ''}`}
                  onClick={handleRevoke}
                >
                  Revoke
                </FormButton>
              </div>
            )}

            <>
              <div className="amazon-business-accounts">
                {amazonBusinessConfig.email && (
                  <>
                    <div className="section-title">
                      <h4>Main Amazon account</h4>{' '}
                      <Tooltip title="Revoke Access">
                        <Button
                          type="default"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          size="small"
                          color="#fff"
                          onClick={handleRevoke}
                        />
                      </Tooltip>
                    </div>

                    <div className="amazon-business-accounts-radio-button-row">
                      {amazonBusinessConfig.email}
                    </div>
                  </>
                )}
                {additionalAccounts && additionalAccounts.length > 0 && (
                  <div className="amazon-business-accounts">
                    <h4>Backup Amazon accounts</h4>

                    {additionalAccounts.map((account, index) => (
                      <>
                        <div key={index} className="amazon-business-accounts-radio-button-row">
                          {account.email}
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </>
          </>
        )}
      </div>
    </FormRequestBlock>
  );
};

export default AmazonBusiness;
