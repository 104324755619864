import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import * as Sentry from '@sentry/react';
import { AddPaymentMethod, PaymentContent, SubmitButton, Warning } from './style';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { useState } from 'react';
import useAuth from 'hooks/useAuth';
import { useMutation } from '@apollo/client/react';
import { ATTACH_PAYMENT_METHOD } from 'app/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { PaymentIcon, PaymentTypeExtended } from 'react-svg-credit-card-payment-icons';
import { BankOutlined, LoadingOutlined } from '@ant-design/icons';
import useTheme from 'hooks/useTheme';
import { PaymentMethod } from 'app/graphql/generated/admin/graphql';
type Props = {
  onSubmit: (paymentMethod: PaymentMethod) => void;
};

export const PaymentDialog = ({ onSubmit }: Props) => {
  const { user } = useAuth();
  const userState = useAppSelector(selectUserInfo);
  const stripe = useStripe();
  const elements = useElements();
  const [showWarning, setShowWarning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const { theme } = useTheme();

  const [attachPaymentMethod] = useMutation(ATTACH_PAYMENT_METHOD, {
    context: { clientName: 'admin' },
  });

  const handleChange = async (event: StripePaymentElementChangeEvent) => {
    if (event.value.type === 'card') {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!stripe || !elements || !user) {
      Sentry.captureException('Stripe, Elements, or user not available');
      return;
    }
    await elements.submit();

    const pm = await stripe?.createPaymentMethod({
      elements,
      params: { metadata: { developerId: user.uid } },
    });

    if (pm.error) {
      Sentry.captureException('Failed to create payment method - ' + pm.error);
      return;
    }

    if (!pm.paymentMethod) {
      Sentry.captureException('Failed to create payment method');
      return;
    }

    const name = pm.paymentMethod.billing_details.name;
    const firstName = name ? name.split(' ')[0] : user.displayName?.split(' ')[0] ?? '';
    const lastName = name ? name.split(' ')[1] ?? '' : user.displayName?.split(' ')[1] ?? '';

    const res = await attachPaymentMethod({
      variables: {
        input: {
          id: pm.paymentMethod.id,
          billingDetails: {
            firstName,
            lastName,
          },
        },
      },
    });
    if (res.errors) {
      Sentry.captureException('Failed to attach payment method - ' + res.errors);
      return;
    }

    const stripeConfig = res.data?.attachPaymentMethod?.stripeConfig;
    if (!stripeConfig) {
      Sentry.captureException('Failed to attach payment method - customer does not exist');
      return;
    }
    const paymentMethod = stripeConfig.paymentMethod;

    if (!paymentMethod) {
      Sentry.captureException('Failed to attach payment method - paymentMethod does not exist');
      return;
    }
    onSubmit(paymentMethod);

    setIsSubmitting(false);
    setOpen(false);
  };

  const getPaymentIcon = () => {
    const paymentMethod = userState?.stripe?.paymentMethod;
    switch (paymentMethod?.__typename) {
      case 'PaymentMethodCard':
        return (
          <PaymentIcon
            type={paymentMethod.brand as PaymentTypeExtended}
            format="flatRounded"
            width={30}
            height={19}
            style={{ borderRadius: '3px' }}
          />
        );
      case 'PaymentMethodACH':
        return <BankOutlined style={{ fontSize: '16px' }} />;
    }
    return (
      <PaymentIcon
        type="Generic"
        format="flatRounded"
        width={30}
        height={19}
        style={{ borderRadius: '3px' }}
      />
    );
  };

  const getLast4 = () => {
    const paymentMethod = userState?.stripe?.paymentMethod;
    switch (paymentMethod?.__typename) {
      case 'PaymentMethodCard':
        return paymentMethod?.last4;
      case 'PaymentMethodACH':
        return paymentMethod?.last4AccountNumber;
    }
    return '';
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <AddPaymentMethod>
          {!userState?.stripe?.paymentMethod ? (
            <span>Add Payment Method</span>
          ) : (
            <div>
              Update Payment: {getPaymentIcon()} •••{getLast4()}
            </div>
          )}
        </AddPaymentMethod>
      </DialogTrigger>
      <DialogContent style={{ borderColor: '#333' }}>
        <DialogHeader>
          <DialogTitle>Add Payment Method</DialogTitle>
          <DialogDescription>
            <PaymentContent>
              <PaymentElement
                options={{
                  paymentMethodOrder: ['us_bank_account', 'card'],
                  fields: { billingDetails: { name: 'auto' } },
                }}
                onChange={handleChange}
              />
              {showWarning && (
                <Warning>
                  Note: Using a card will incur an additional 3% charge to your monthly balance
                </Warning>
              )}
            </PaymentContent>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <SubmitButton disabled={isSubmitting} onClick={handleSubmit} thememode={theme}>
            {isSubmitting ? <LoadingOutlined /> : 'Submit'}
          </SubmitButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
