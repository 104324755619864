import AccountMenu from 'components/AccountMenu';
import RyeLogo from 'components/Logo';
import MainNav from 'components/Navigation';

import './style.scss';
import { env } from 'config/env';

interface LayoutProps {
  children: JSX.Element;
  fitted?: boolean;
}

const Layout = ({ children, fitted }: LayoutProps) => {
  // So that developers can quickly tell which environment they're in
  const shouldShowEnvBanner = env.REACT_APP_NODE_ENV !== 'production';

  return (
    <>
      {shouldShowEnvBanner && (
        <div className="env-banner">
          <p className="env-banner__text">
            You're currently using Rye in the <strong>{env.REACT_APP_NODE_ENV}</strong> environment
          </p>
        </div>
      )}

      <div className={`layout ${fitted ? 'layout--fitted' : ''} full-width`}>
        <header className="layout-header">
          <RyeLogo />
          <AccountMenu />
        </header>

        <MainNav />

        <main className="layout-main">{children}</main>
      </div>
    </>
  );
};

export default Layout;
