import { useEffect, useState } from 'react';

import FormRequestBlock from 'components/FormRequestBlock';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserInfo, selectUserMarginLoading, updateMarginAmount } from 'app/store/user';
import FormSelect from 'components/FormSelect';
import FormButton from 'components/FormButton';
import FormInput from 'components/FormInput';
import { INPUT_PATTERN, trackSegmentEvent } from 'app/utils';

import './style.scss';
import { ACTION, SOURCE } from 'app/analytics';

enum MarginType {
  Percent = '%',
  USD = '$',
}
const convertToMarginType = (marginIsPercent?: boolean): MarginType =>
  marginIsPercent ? MarginType.Percent : MarginType.USD;

const convertToIsPercent = (marginType: MarginType): boolean => marginType === MarginType.Percent;

const MarginBlock = () => {
  const { marginAmount, marginIsPercent } = useAppSelector(selectUserInfo) || {};
  const formLoading = useAppSelector(selectUserMarginLoading);
  const [inputValue, setInputValue] = useState(marginAmount);
  const [selectValue, setSelectValue] = useState(convertToMarginType(marginIsPercent));
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInputValue(marginAmount);
    setSelectValue(convertToMarginType(marginIsPercent));
  }, [marginAmount, marginIsPercent]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(updateMarginAmount(inputValue, convertToIsPercent(selectValue)));
    trackSegmentEvent(SOURCE.MARGIN_AMOUNT_MODULE, ACTION.CLICK, 'update_button', {
      marginIsPercent: marginIsPercent,
    });
  };

  const isPercent = convertToIsPercent(selectValue);

  return (
    <FormRequestBlock
      title="Margin amount"
      onSubmit={handleSubmit}
      loading={formLoading}
      text={
        <p>Please enter the amount of margin you would like to add to the initial product price</p>
      }
    >
      <FormInput
        id="margin-amount"
        className="margin-input"
        type="number"
        defaultValue={inputValue}
        placeholder="Enter amount"
        min={0}
        max={isPercent ? 100 : 1000000}
        pattern={isPercent ? INPUT_PATTERN.PERCENTS : INPUT_PATTERN.DIGITS}
        size={isPercent ? 3 : 7}
        maxLength={isPercent ? 3 : 7}
        onChange={(value) => setInputValue(Number(value))}
      />
      <FormSelect
        id="margin-amount"
        className="margin-select"
        onChange={(value) => setSelectValue(value as MarginType)}
        selectedDefaultValue={selectValue}
        listItems={[
          { title: '% Percent', value: MarginType.Percent },
          { title: '$ USD', value: MarginType.USD },
        ]}
      />
      <FormButton disabled={formLoading}>Save</FormButton>
    </FormRequestBlock>
  );
};

export default MarginBlock;
