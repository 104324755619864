import { createRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { trackSegmentEvent } from 'app/utils';
import { ACTION, SOURCE } from 'app/analytics';
import UserAvatar from 'components/Avatar';
import FormButton from 'components/FormButton';
import useAuth from 'hooks/useAuth';
import useOutside from 'hooks/useOutside';
import useTheme from 'hooks/useTheme';
import useNotifications from 'hooks/useNotifications';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';

import './style.scss';
import { env } from 'config/env';
import { getSwitchLink } from 'config/constants';
import { ThemeMode } from 'providers/ThemeProvider';
import { Switch } from 'components/ui/switch';
import { Label } from 'components/ui/label';

const Account = () => {
  const { theme, switchTheme } = useTheme();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const { notificationsToDisplay, disableNotification } = useNotifications();
  const menuRef = createRef<HTMLDivElement>();

  useOutside(menuRef, () => {
    setOpen(false);
  });

  const handleLogoutClick = () => {
    logout();
    trackSegmentEvent(SOURCE.CONSOLE_MENU, ACTION.CLICK, 'logout_button');
  };

  const handleSwitchThemeClick = () => {
    switchTheme();
    trackSegmentEvent(SOURCE.CONSOLE_MENU, ACTION.CLICK, 'theme_button');
  };

  const handleOnClick = () => {
    !open && setOpen(!open);
    trackSegmentEvent(SOURCE.CONSOLE_MENU, ACTION.CLICK, 'menu_button');
  };

  const disableNotificationOnClick = (id: string) => {
    disableNotification(id);
  };

  const switchLabel = () => {
    switch (env.REACT_APP_NODE_ENV) {
      case 'staging':
        return 'staging';
      case 'development':
        return 'development';
      default:
        return 'production';
    }
  };

  const handleSwitch = (isChecked: boolean) => {
    if (!isChecked) {
      window.location.href = getSwitchLink();
    } else {
      window.location.href = 'https://staging.console.rye.com';
    }
  };

  return (
    <div className="account">
      <div className="account__wrapper">
        {/* Allow the user to switch environments easily */}

        <>
          <Switch
            defaultChecked={env.REACT_APP_NODE_ENV !== 'production'}
            onCheckedChange={handleSwitch}
            id="environment-switch"
          />
          <Label htmlFor="environment-switch">{switchLabel()}</Label>
        </>

        {/* Menu button */}
        <div ref={menuRef} onClick={handleOnClick}>
          <UserAvatar />

          {open && (
            <ul className="account-menu">
              {notificationsToDisplay.map((n) => (
                <li
                  key={n?.id}
                  className="account-menu-promo"
                  onClick={() => disableNotificationOnClick(n?.id)}
                >
                  <Link
                    onClick={() => {
                      trackSegmentEvent(
                        SOURCE.CONSOLE_MENU,
                        ACTION.CLICK,
                        'account_notification_link',
                      );
                    }}
                    to={n?.link}
                  >
                    {n?.title}
                  </Link>
                </li>
              ))}
              <li className="account-menu-item first">{user?.email}</li>
              <li className="account-menu-item">{user?.displayName}</li>
              <li className="account-menu-item buttons">
                <FormButton
                  title="Logout"
                  id={'account-menu-logout'}
                  className="account-menu-button logout"
                  onClick={handleLogoutClick}
                >
                  Logout
                </FormButton>
                <FormButton
                  title={`Switch to ${
                    theme === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light
                  } mode`}
                  id={'account-menu-theme'}
                  className="account-menu-button theme"
                  onClick={handleSwitchThemeClick}
                >
                  {theme === ThemeMode.Light ? <MoonIcon /> : <SunIcon />}
                </FormButton>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Account;
