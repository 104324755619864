import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import authAPI from 'app/auth';
import { fetchUserInfo, cleanUserInfo } from 'app/store/user';
import { useAppDispatch } from 'app/store';
import {
  cleanInventoryRequests,
  fetchInventoryRequests,
  fetchProductRequestCount,
  fetchStoreRequestCount,
} from 'app/store/inventoryRequests';
import { cleanCheckoutRequests, fetchCheckoutRequests } from 'app/store/checkoutRequests';
import { fetchClientIp } from '../app/store/clientIp';

let currentUid: string | null = null;

type AuthError = Error | string | undefined;

const useAuth = () => {
  const [user, loading, error] = useAuthState(authAPI.auth);
  const [authError, setAuthError] = useState<AuthError>(error);
  const dispatch = useAppDispatch();

  const setAuthErrorWithTimer = (error: string | undefined) => {
    if (error) {
      setAuthError(error);
      setTimeout(() => setAuthError(''), 1000); // reset state of error to trigger next error
    }
  };

  // Register with Google account, or sign in if account already exists
  const signInOrRegisterWithGoogle = async (website?: string) => {
    const { error } = await authAPI.signInOrRegisterWithGoogle(website);

    if (error) {
      setAuthErrorWithTimer(error);
    }
  };

  const logInWithEmailAndPassword = async (email: string, password: string) => {
    const { error } = await authAPI.logInWithEmailAndPassword(email, password);
    setAuthErrorWithTimer(error);
  };

  const registerWithEmailAndPassword = async (
    firstName: string,
    lastName: string,
    email: string,
    website: string,
    password: string,
  ) => {
    const { error } = await authAPI.registerWithEmailAndPassword({
      firstName,
      lastName,
      website,
      email,
      password,
    });

    if (error) {
      setAuthErrorWithTimer(error);
    }
  };

  const sendPasswordReset = async (email: string) => {
    const { error } = await authAPI.sendPasswordReset(email);
    setAuthErrorWithTimer(error);
  };

  // Clean our diff app data on logout (once)
  const logOutAccount = async () => {
    authAPI.logout();

    dispatch(cleanUserInfo());
    dispatch(cleanInventoryRequests());
    dispatch(cleanCheckoutRequests());
  };

  // Load diff app data on login (once)
  useEffect(() => {
    if (currentUid !== user?.uid) {
      currentUid = user?.uid || null;

      if (currentUid && user) {
        (async () => {
          dispatch(fetchUserInfo(currentUid, user));
          dispatch(fetchInventoryRequests(currentUid));
          dispatch(fetchCheckoutRequests(currentUid));
          dispatch(fetchStoreRequestCount(currentUid));
          dispatch(fetchProductRequestCount(currentUid));
          dispatch(fetchClientIp());
        })();
      }
    }
  }, [user, dispatch]);

  return {
    user,
    loading,
    error: authError,
    logout: logOutAccount,
    signInOrRegisterWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
  };
};

export default useAuth;
