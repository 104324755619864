/* eslint-disable */

import * as sdk from 'hypertune';

export const queryCode = `id/72082235-665e-49d0-834f-470e0a8b2079`;

export const query = {
  Query: {
    objectTypeName: 'Query',
    selection: {
      root: {
        fieldArguments: { __isPartialObject__: true },
        fieldQuery: {
          Root: {
            objectTypeName: 'Root',
            selection: {
              viewCreateOrder: { fieldArguments: {}, fieldQuery: null },
              cityTimezoneMapping: {
                fieldArguments: {},
                fieldQuery: {
                  CityTimezoneMapping: {
                    objectTypeName: 'CityTimezoneMapping',
                    selection: {
                      city: { fieldArguments: {}, fieldQuery: null },
                      timeZone: { fieldArguments: {}, fieldQuery: null },
                    },
                  },
                },
              },
              amazonTrackingPageHTML: { fieldArguments: {}, fieldQuery: null },
              amazonOrderTrackingURLFromEmail: { fieldArguments: {}, fieldQuery: null },
              viewBilling: { fieldArguments: {}, fieldQuery: null },
              developerManagedCommissionAutoEnrolledDevelopers: {
                fieldArguments: {},
                fieldQuery: null,
              },
              discountCodeFlowAutoEnrolledDevelopers: { fieldArguments: {}, fieldQuery: null },
              enableDiscountCodes: { fieldArguments: {}, fieldQuery: null },
              amazonStoreErrors: {
                fieldArguments: {},
                fieldQuery: {
                  AmazonStoreErrors: {
                    objectTypeName: 'AmazonStoreErrors',
                    selection: {
                      error020013: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error003052: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error010003: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error001001: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error015005: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error008007: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error007003: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error015003: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error007007: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                      error008004: {
                        fieldArguments: {},
                        fieldQuery: {
                          AmazonStoreError: {
                            objectTypeName: 'AmazonStoreError',
                            selection: {
                              code: { fieldArguments: {}, fieldQuery: null },
                              reason: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              amazonErrorCodes: {
                fieldArguments: {},
                fieldQuery: {
                  AmazonErrorCodes: {
                    objectTypeName: 'AmazonErrorCodes',
                    selection: {
                      error008007: { fieldArguments: {}, fieldQuery: null },
                      error007003: { fieldArguments: {}, fieldQuery: null },
                      error015003: { fieldArguments: {}, fieldQuery: null },
                      error007007: { fieldArguments: {}, fieldQuery: null },
                      error008004: { fieldArguments: {}, fieldQuery: null },
                    },
                  },
                },
              },
              blacklistedOrderErrorReasons: { fieldArguments: {}, fieldQuery: null },
              sellAnythingProductWebhooks: { fieldArguments: {}, fieldQuery: null },
              emailOverrides: {
                fieldArguments: {},
                fieldQuery: {
                  EmailOverrides: {
                    objectTypeName: 'EmailOverrides',
                    selection: {
                      overrides: {
                        fieldArguments: {},
                        fieldQuery: {
                          DeveloperOverride: {
                            objectTypeName: 'DeveloperOverride',
                            selection: {
                              developerId: { fieldArguments: {}, fieldQuery: null },
                              emails: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              company: { fieldArguments: {}, fieldQuery: null },
              sandbox: { fieldArguments: {}, fieldQuery: null },
              shopifyDeveloperPermissions: {
                fieldArguments: {},
                fieldQuery: {
                  ShopifyDeveloperPermissions: {
                    objectTypeName: 'ShopifyDeveloperPermissions',
                    selection: {
                      shopifyDeveloperPermissions: {
                        fieldArguments: {},
                        fieldQuery: {
                          ShopifyDeveloperPermission: {
                            objectTypeName: 'ShopifyDeveloperPermission',
                            selection: {
                              id: { fieldArguments: {}, fieldQuery: null },
                              allowedStores: {
                                fieldArguments: {},
                                fieldQuery: {
                                  ShopifyStore: {
                                    objectTypeName: 'ShopifyStore',
                                    selection: { url: { fieldArguments: {}, fieldQuery: null } },
                                  },
                                },
                              },
                              allowedQueries: { fieldArguments: {}, fieldQuery: null },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              sendToManualFulfillment: { fieldArguments: {}, fieldQuery: null },
            },
          },
        },
      },
    },
  },
};

function mergeQueryAndArgs(
  query: sdk.Query<sdk.ObjectValueWithVariables>,
  queryArgs: sdk.ObjectValueWithVariables | null,
  unwrapObjectArgs = false,
): sdk.Query<sdk.ObjectValueWithVariables> {
  return Object.fromEntries(
    Object.entries(query).map(([objectTypeName, fragment]) => {
      const objectArgs = unwrapObjectArgs
        ? queryArgs && queryArgs[objectTypeName] && queryArgs[objectTypeName] instanceof Object
          ? (queryArgs[objectTypeName] as sdk.ObjectValueWithVariables)
          : null
        : queryArgs;

      return [
        objectTypeName,
        {
          objectTypeName,
          selection: Object.fromEntries(
            Object.entries(fragment.selection).map(([fieldName, { fieldQuery }]) => {
              const fieldArgs =
                objectArgs && objectArgs[fieldName] && objectArgs[fieldName] instanceof Object
                  ? (objectArgs[fieldName] as sdk.ObjectValueWithVariables)
                  : null;

              return [
                fieldName,
                {
                  fieldArguments: {
                    ...(fieldArgs && fieldArgs.args
                      ? (fieldArgs.args as sdk.ObjectValueWithVariables)
                      : {}),
                  },
                  fieldQuery: fieldQuery ? mergeQueryAndArgs(fieldQuery, fieldArgs, true) : null,
                },
              ];
            }),
          ),
        },
      ];
    }),
  );
}

/**
 * @deprecated use '@vercel/flags/providers/hypertune' package instead.
 */
export const vercelFlagDefinitions = {
  viewCreateOrder: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EviewCreateOrder',
  },
  amazonTrackingPageHTML: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonTrackingPageHTML',
  },
  amazonOrderTrackingURLFromEmail: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonOrderTrackingURLFromEmail',
  },
  viewBilling: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EviewBilling',
  },
  developerManagedCommissionAutoEnrolledDevelopers: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EdeveloperManagedCommissionAutoEnrolledDevelopers',
  },
  discountCodeFlowAutoEnrolledDevelopers: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EdiscountCodeFlowAutoEnrolledDevelopers',
  },
  enableDiscountCodes: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EenableDiscountCodes',
  },
  sandbox: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3Esandbox',
  },
  sendToManualFulfillment: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EsendToManualFulfillment',
    description:
      'NOTE: Do not remove "integration_tests_user_id_1", it will break integration tests.',
  },
};

export type Rec = {};

export const EnvironmentEnumValues = ['PRODUCTION', 'STAGING', 'DEVELOPMENT'] as const;
export type Environment = (typeof EnvironmentEnumValues)[number];

export type Rec3 = {
  id: string;
  email: string;
};

export type Rec2 = {
  environment: Environment;
  user: Rec3;
};

export type RootArgs = {
  context: Rec2;
};

export type CityTimezoneMapping = {
  city: string;
  timeZone: string;
};

const cityTimezoneMappingFallback = { city: '', timeZone: '' };

export class CityTimezoneMappingNode extends sdk.Node {
  typeName = 'CityTimezoneMapping' as const;

  get({
    fallback = cityTimezoneMappingFallback as CityTimezoneMapping,
  }: { fallback?: CityTimezoneMapping } = {}): CityTimezoneMapping {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as CityTimezoneMapping;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22CityTimezoneMapping%22%2C%22selectedChildName%22%3A%22city%22%7D})
   */
  city({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('city', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22CityTimezoneMapping%22%2C%22selectedChildName%22%3A%22timeZone%22%7D})
   */
  timeZone({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('timeZone', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

export const AmazonStoreErrorReasonCodeEnumValues = [
  'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON',
  'CANT_SHIP_TO_COUNTRY',
  'OUT_OF_STOCK',
  'DELIVERY_TOO_LATE',
  'CANT_SHIP_TO_ADDRESS',
  'QUANTITY_LIMIT_EXCEEDED',
  'NO_SELLER_OPTIONS',
  'UNKNOWN',
  'INSUFFICIENT_STOCK',
  'PRODUCT_NOT_SUPPORTED',
  'INVALID_ADDRESS',
  'REQUIRED_CREDENTIALS',
] as const;
export type AmazonStoreErrorReasonCode = (typeof AmazonStoreErrorReasonCodeEnumValues)[number];

export class AmazonStoreErrorReasonCodeNode extends sdk.Node {
  typeName = 'AmazonStoreErrorReasonCode' as const;

  get({
    fallback,
    shouldReturnUnrecognizedValues = false,
  }: {
    fallback: AmazonStoreErrorReasonCode;
    shouldReturnUnrecognizedValues?: boolean;
  }): AmazonStoreErrorReasonCode {
    const value = this.evaluate(/* query */ null, fallback);

    if (typeof value !== 'string' || !value) {
      this.logUnexpectedValueError(value);
      return fallback;
    }
    if (
      !shouldReturnUnrecognizedValues &&
      !AmazonStoreErrorReasonCodeEnumValues.includes(value as AmazonStoreErrorReasonCode)
    ) {
      this.logUnexpectedValueError(value);
      return fallback;
    }

    return value as AmazonStoreErrorReasonCode;
  }
}

export type AmazonStoreError = {
  code: AmazonStoreErrorReasonCode;
  reason: string;
};

const amazonStoreErrorFallback = { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' };

export class AmazonStoreErrorNode extends sdk.Node {
  typeName = 'AmazonStoreError' as const;

  get({
    fallback = amazonStoreErrorFallback as AmazonStoreError,
  }: { fallback?: AmazonStoreError } = {}): AmazonStoreError {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as AmazonStoreError;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22AmazonStoreError%22%2C%22selectedChildName%22%3A%22code%22%7D})
   */
  code({
    args = {},
    fallback,
    shouldReturnUnrecognizedValues = false,
  }: {
    args?: Rec;
    fallback: AmazonStoreErrorReasonCode;
    shouldReturnUnrecognizedValues?: boolean;
  }): AmazonStoreErrorReasonCode {
    const props0 = this.getField('code', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      (expression0.type === 'StringExpression' || expression0.type === 'EnumExpression') &&
      AmazonStoreErrorReasonCodeEnumValues.includes(expression0.value as AmazonStoreErrorReasonCode)
    ) {
      const node = new AmazonStoreErrorReasonCodeNode(props0);
      return node.get({ fallback, shouldReturnUnrecognizedValues });
    }

    const node = new AmazonStoreErrorReasonCodeNode(props0);
    node._logUnexpectedTypeError();
    return fallback;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22AmazonStoreError%22%2C%22selectedChildName%22%3A%22reason%22%7D})
   */
  reason({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('reason', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

export type AmazonStoreErrors = {
  error020013: AmazonStoreError;
  error003052: AmazonStoreError;
  error010003: AmazonStoreError;
  error001001: AmazonStoreError;
  error015005: AmazonStoreError;
  error008007: AmazonStoreError;
  error007003: AmazonStoreError;
  error015003: AmazonStoreError;
  error007007: AmazonStoreError;
  error008004: AmazonStoreError;
};

const amazonStoreErrorsFallback = {
  error020013: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error003052: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error010003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error001001: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error015005: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error008007: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error007003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error015003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error007007: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  error008004: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
};

export class AmazonStoreErrorsNode extends sdk.Node {
  typeName = 'AmazonStoreErrors' as const;

  get({
    fallback = amazonStoreErrorsFallback as AmazonStoreErrors,
  }: { fallback?: AmazonStoreErrors } = {}): AmazonStoreErrors {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as AmazonStoreErrors;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror020013})
   */
  error020013({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error020013', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror003052})
   */
  error003052({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error003052', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror010003})
   */
  error010003({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error010003', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror001001})
   */
  error001001({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error001001', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror015005})
   */
  error015005({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error015005', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror008007})
   */
  error008007({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error008007', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror007003})
   */
  error007003({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error007003', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror015003})
   */
  error015003({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error015003', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror007007})
   */
  error007007({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error007007', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors%3Eerror008004})
   */
  error008004({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorNode {
    const props0 = this.getField('error008004', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreError'
    ) {
      return new AmazonStoreErrorNode(props0);
    }

    const node = new AmazonStoreErrorNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }
}

export type AmazonErrorCodes = {
  error008007: string;
  error007003: string;
  error015003: string;
  error007007: string;
  error008004: string;
};

const amazonErrorCodesFallback = {
  error008007: '',
  error007003: '',
  error015003: '',
  error007007: '',
  error008004: '',
};

export class AmazonErrorCodesNode extends sdk.Node {
  typeName = 'AmazonErrorCodes' as const;

  get({
    fallback = amazonErrorCodesFallback as AmazonErrorCodes,
  }: { fallback?: AmazonErrorCodes } = {}): AmazonErrorCodes {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as AmazonErrorCodes;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonErrorCodes%3Eerror008007})
   */
  error008007({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('error008007', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonErrorCodes%3Eerror007003})
   */
  error007003({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('error007003', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonErrorCodes%3Eerror015003})
   */
  error015003({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('error015003', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonErrorCodes%3Eerror007007})
   */
  error007007({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('error007007', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonErrorCodes%3Eerror008004})
   */
  error008004({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('error008004', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

export const MarketplaceEnumValues = ['SHOPIFY', 'AMAZON', 'UNKNOWN'] as const;
export type Marketplace = (typeof MarketplaceEnumValues)[number];

export class MarketplaceNode extends sdk.Node {
  typeName = 'Marketplace' as const;

  get({
    fallback,
    shouldReturnUnrecognizedValues = false,
  }: {
    fallback: Marketplace;
    shouldReturnUnrecognizedValues?: boolean;
  }): Marketplace {
    const value = this.evaluate(/* query */ null, fallback);

    if (typeof value !== 'string' || !value) {
      this.logUnexpectedValueError(value);
      return fallback;
    }
    if (!shouldReturnUnrecognizedValues && !MarketplaceEnumValues.includes(value as Marketplace)) {
      this.logUnexpectedValueError(value);
      return fallback;
    }

    return value as Marketplace;
  }
}

export type DeveloperOverride = {
  developerId: string;
  emails: string[];
};

const developerOverrideFallback = { developerId: '', emails: [] };

export class DeveloperOverrideNode extends sdk.Node {
  typeName = 'DeveloperOverride' as const;

  get({
    fallback = developerOverrideFallback as DeveloperOverride,
  }: { fallback?: DeveloperOverride } = {}): DeveloperOverride {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as DeveloperOverride;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22DeveloperOverride%22%2C%22selectedChildName%22%3A%22developerId%22%7D})
   */
  developerId({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('developerId', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22DeveloperOverride%22%2C%22selectedChildName%22%3A%22emails%22%7D})
   */
  emails({
    args = {},
    itemFallback: fallback,
    listFallbackLength = 0,
  }: {
    args?: Rec;
    itemFallback: string;
    listFallbackLength?: number;
  }): string[] {
    const props0 = this.getField('emails', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (expression1 && expression1.type === 'StringExpression') {
        const node = new sdk.StringNode(props1);
        return node.get({ fallback });
      }

      const node = new sdk.StringNode(props1);
      node._logUnexpectedTypeError();
      return node.get({ fallback });
    });
  }
}

export type EmailOverrides = {
  overrides: DeveloperOverride[];
};

const emailOverridesFallback = { overrides: [] };

export class EmailOverridesNode extends sdk.Node {
  typeName = 'EmailOverrides' as const;

  get({
    fallback = emailOverridesFallback as EmailOverrides,
  }: { fallback?: EmailOverrides } = {}): EmailOverrides {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as EmailOverrides;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EemailOverrides%3Eoverrides})
   */
  overrides({
    args = {},
    listFallbackLength = 0,
  }: { args?: Rec; listFallbackLength?: number } = {}): DeveloperOverrideNode[] {
    const props0 = this.getField('overrides', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (
        expression1 &&
        expression1.type === 'ObjectExpression' &&
        expression1.objectTypeName === 'DeveloperOverride'
      ) {
        return new DeveloperOverrideNode(props1);
      }

      const node = new DeveloperOverrideNode(props1);
      node._logUnexpectedTypeError();
      return node;
    });
  }
}

export type ShopifyStore = {
  url: string;
};

const shopifyStoreFallback = { url: '' };

export class ShopifyStoreNode extends sdk.Node {
  typeName = 'ShopifyStore' as const;

  get({
    fallback = shopifyStoreFallback as ShopifyStore,
  }: { fallback?: ShopifyStore } = {}): ShopifyStore {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as ShopifyStore;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22ShopifyStore%22%2C%22selectedChildName%22%3A%22url%22%7D})
   */
  url({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('url', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

export const ShopifyAllowedQueryEnumValues = [
  'DRAFT_ORDER_CREATE_GQL',
  'DRAFT_ORDER_COMPLETE_GQL',
  'ORDER_BY_ID_GQL',
  'ERROR',
  'VARIANT_BY_ID_GQL',
] as const;
export type ShopifyAllowedQuery = (typeof ShopifyAllowedQueryEnumValues)[number];

export class ShopifyAllowedQueryNode extends sdk.Node {
  typeName = 'ShopifyAllowedQuery' as const;

  get({
    fallback,
    shouldReturnUnrecognizedValues = false,
  }: {
    fallback: ShopifyAllowedQuery;
    shouldReturnUnrecognizedValues?: boolean;
  }): ShopifyAllowedQuery {
    const value = this.evaluate(/* query */ null, fallback);

    if (typeof value !== 'string' || !value) {
      this.logUnexpectedValueError(value);
      return fallback;
    }
    if (
      !shouldReturnUnrecognizedValues &&
      !ShopifyAllowedQueryEnumValues.includes(value as ShopifyAllowedQuery)
    ) {
      this.logUnexpectedValueError(value);
      return fallback;
    }

    return value as ShopifyAllowedQuery;
  }
}

export type ShopifyDeveloperPermission = {
  id: string;
  allowedStores: ShopifyStore[];
  allowedQueries: ShopifyAllowedQuery[];
};

const shopifyDeveloperPermissionFallback = { id: '', allowedStores: [], allowedQueries: [] };

export class ShopifyDeveloperPermissionNode extends sdk.Node {
  typeName = 'ShopifyDeveloperPermission' as const;

  get({
    fallback = shopifyDeveloperPermissionFallback as ShopifyDeveloperPermission,
  }: { fallback?: ShopifyDeveloperPermission } = {}): ShopifyDeveloperPermission {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as ShopifyDeveloperPermission;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22ShopifyDeveloperPermission%22%2C%22selectedChildName%22%3A%22id%22%7D})
   */
  id({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('id', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22ShopifyDeveloperPermission%22%2C%22selectedChildName%22%3A%22allowedStores%22%7D})
   */
  allowedStores({
    args = {},
    listFallbackLength = 0,
  }: { args?: Rec; listFallbackLength?: number } = {}): ShopifyStoreNode[] {
    const props0 = this.getField('allowedStores', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (
        expression1 &&
        expression1.type === 'ObjectExpression' &&
        expression1.objectTypeName === 'ShopifyStore'
      ) {
        return new ShopifyStoreNode(props1);
      }

      const node = new ShopifyStoreNode(props1);
      node._logUnexpectedTypeError();
      return node;
    });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/schema?selected_schema_type=%7B%22type%22%3A%22object%22%2C%22name%22%3A%22ShopifyDeveloperPermission%22%2C%22selectedChildName%22%3A%22allowedQueries%22%7D})
   */
  allowedQueries({
    args = {},
    itemFallback: fallback,
    listFallbackLength = 0,
    shouldReturnUnrecognizedValues = false,
  }: {
    args?: Rec;
    itemFallback: ShopifyAllowedQuery;
    listFallbackLength?: number;
    shouldReturnUnrecognizedValues?: boolean;
  }): ShopifyAllowedQuery[] {
    const props0 = this.getField('allowedQueries', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (
        expression1 &&
        (expression1.type === 'StringExpression' || expression1.type === 'EnumExpression') &&
        ShopifyAllowedQueryEnumValues.includes(expression1.value as ShopifyAllowedQuery)
      ) {
        const node = new ShopifyAllowedQueryNode(props1);
        return node.get({ fallback, shouldReturnUnrecognizedValues });
      }

      const node = new ShopifyAllowedQueryNode(props1);
      node._logUnexpectedTypeError();
      return fallback;
    });
  }
}

export type ShopifyDeveloperPermissions = {
  shopifyDeveloperPermissions: ShopifyDeveloperPermission[];
};

const shopifyDeveloperPermissionsFallback = { shopifyDeveloperPermissions: [] };

export class ShopifyDeveloperPermissionsNode extends sdk.Node {
  typeName = 'ShopifyDeveloperPermissions' as const;

  get({
    fallback = shopifyDeveloperPermissionsFallback as ShopifyDeveloperPermissions,
  }: { fallback?: ShopifyDeveloperPermissions } = {}): ShopifyDeveloperPermissions {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as ShopifyDeveloperPermissions;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EshopifyDeveloperPermissions%3EshopifyDeveloperPermissions})
   */
  shopifyDeveloperPermissions({
    args = {},
    listFallbackLength = 0,
  }: { args?: Rec; listFallbackLength?: number } = {}): ShopifyDeveloperPermissionNode[] {
    const props0 = this.getField('shopifyDeveloperPermissions', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (
        expression1 &&
        expression1.type === 'ObjectExpression' &&
        expression1.objectTypeName === 'ShopifyDeveloperPermission'
      ) {
        return new ShopifyDeveloperPermissionNode(props1);
      }

      const node = new ShopifyDeveloperPermissionNode(props1);
      node._logUnexpectedTypeError();
      return node;
    });
  }
}

export type Root = {
  viewCreateOrder: boolean;
  cityTimezoneMapping: CityTimezoneMapping[];
  amazonTrackingPageHTML: boolean;
  amazonOrderTrackingURLFromEmail: boolean;
  viewBilling: boolean;
  developerManagedCommissionAutoEnrolledDevelopers: boolean;
  discountCodeFlowAutoEnrolledDevelopers: boolean;
  enableDiscountCodes: boolean;
  amazonStoreErrors: AmazonStoreErrors;
  amazonErrorCodes: AmazonErrorCodes;
  blacklistedOrderErrorReasons: string[];
  sellAnythingProductWebhooks: Marketplace[];
  emailOverrides: EmailOverrides;
  company: string;
  sandbox: boolean;
  shopifyDeveloperPermissions: ShopifyDeveloperPermissions;
  /**
   * NOTE: Do not remove "integration_tests_user_id_1", it will break integration tests.
   */
  sendToManualFulfillment: boolean;
};

const rootFallback = {
  viewCreateOrder: false,
  cityTimezoneMapping: [],
  amazonTrackingPageHTML: false,
  amazonOrderTrackingURLFromEmail: false,
  viewBilling: false,
  developerManagedCommissionAutoEnrolledDevelopers: false,
  discountCodeFlowAutoEnrolledDevelopers: false,
  enableDiscountCodes: false,
  amazonStoreErrors: {
    error020013: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error003052: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error010003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error001001: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error015005: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error008007: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error007003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error015003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error007007: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    error008004: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
  },
  amazonErrorCodes: {
    error008007: '',
    error007003: '',
    error015003: '',
    error007007: '',
    error008004: '',
  },
  blacklistedOrderErrorReasons: [],
  sellAnythingProductWebhooks: [],
  emailOverrides: { overrides: [] },
  company: '',
  sandbox: false,
  shopifyDeveloperPermissions: { shopifyDeveloperPermissions: [] },
  sendToManualFulfillment: false,
};

export class RootNode extends sdk.Node {
  typeName = 'Root' as const;

  getRootArgs(): RootArgs {
    const { step } = this.props;
    return (step?.type === 'GetFieldStep' ? step.fieldArguments : {}) as RootArgs;
  }

  get({ fallback = rootFallback as Root }: { fallback?: Root } = {}): Root {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as Root;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EviewCreateOrder})
   */
  viewCreateOrder({ args = {}, fallback }: { args?: Rec; fallback: boolean }): boolean {
    const props0 = this.getField('viewCreateOrder', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EcityTimezoneMapping})
   */
  cityTimezoneMapping({
    args = {},
    listFallbackLength = 0,
  }: { args?: Rec; listFallbackLength?: number } = {}): CityTimezoneMappingNode[] {
    const props0 = this.getField('cityTimezoneMapping', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (
        expression1 &&
        expression1.type === 'ObjectExpression' &&
        expression1.objectTypeName === 'CityTimezoneMapping'
      ) {
        return new CityTimezoneMappingNode(props1);
      }

      const node = new CityTimezoneMappingNode(props1);
      node._logUnexpectedTypeError();
      return node;
    });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonTrackingPageHTML})
   */
  amazonTrackingPageHTML({ args = {}, fallback }: { args?: Rec; fallback: boolean }): boolean {
    const props0 = this.getField('amazonTrackingPageHTML', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonOrderTrackingURLFromEmail})
   */
  amazonOrderTrackingURLFromEmail({
    args = {},
    fallback,
  }: {
    args?: Rec;
    fallback: boolean;
  }): boolean {
    const props0 = this.getField('amazonOrderTrackingURLFromEmail', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EviewBilling})
   */
  viewBilling({ args = {}, fallback }: { args?: Rec; fallback: boolean }): boolean {
    const props0 = this.getField('viewBilling', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EdeveloperManagedCommissionAutoEnrolledDevelopers})
   */
  developerManagedCommissionAutoEnrolledDevelopers({
    args = {},
    fallback,
  }: {
    args?: Rec;
    fallback: boolean;
  }): boolean {
    const props0 = this.getField('developerManagedCommissionAutoEnrolledDevelopers', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EdiscountCodeFlowAutoEnrolledDevelopers})
   */
  discountCodeFlowAutoEnrolledDevelopers({
    args = {},
    fallback,
  }: {
    args?: Rec;
    fallback: boolean;
  }): boolean {
    const props0 = this.getField('discountCodeFlowAutoEnrolledDevelopers', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EenableDiscountCodes})
   */
  enableDiscountCodes({ args = {}, fallback }: { args?: Rec; fallback: boolean }): boolean {
    const props0 = this.getField('enableDiscountCodes', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonStoreErrors})
   */
  amazonStoreErrors({ args = {} }: { args?: Rec } = {}): AmazonStoreErrorsNode {
    const props0 = this.getField('amazonStoreErrors', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonStoreErrors'
    ) {
      return new AmazonStoreErrorsNode(props0);
    }

    const node = new AmazonStoreErrorsNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EamazonErrorCodes})
   */
  amazonErrorCodes({ args = {} }: { args?: Rec } = {}): AmazonErrorCodesNode {
    const props0 = this.getField('amazonErrorCodes', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'AmazonErrorCodes'
    ) {
      return new AmazonErrorCodesNode(props0);
    }

    const node = new AmazonErrorCodesNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EblacklistedOrderErrorReasons})
   */
  blacklistedOrderErrorReasons({
    args = {},
    itemFallback: fallback,
    listFallbackLength = 0,
  }: {
    args?: Rec;
    itemFallback: string;
    listFallbackLength?: number;
  }): string[] {
    const props0 = this.getField('blacklistedOrderErrorReasons', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (expression1 && expression1.type === 'StringExpression') {
        const node = new sdk.StringNode(props1);
        return node.get({ fallback });
      }

      const node = new sdk.StringNode(props1);
      node._logUnexpectedTypeError();
      return node.get({ fallback });
    });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EsellAnythingProductWebhooks})
   */
  sellAnythingProductWebhooks({
    args = {},
    itemFallback: fallback,
    listFallbackLength = 0,
    shouldReturnUnrecognizedValues = false,
  }: {
    args?: Rec;
    itemFallback: Marketplace;
    listFallbackLength?: number;
    shouldReturnUnrecognizedValues?: boolean;
  }): Marketplace[] {
    const props0 = this.getField('sellAnythingProductWebhooks', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (
        expression1 &&
        (expression1.type === 'StringExpression' || expression1.type === 'EnumExpression') &&
        MarketplaceEnumValues.includes(expression1.value as Marketplace)
      ) {
        const node = new MarketplaceNode(props1);
        return node.get({ fallback, shouldReturnUnrecognizedValues });
      }

      const node = new MarketplaceNode(props1);
      node._logUnexpectedTypeError();
      return fallback;
    });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EemailOverrides})
   */
  emailOverrides({ args = {} }: { args?: Rec } = {}): EmailOverridesNode {
    const props0 = this.getField('emailOverrides', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'EmailOverrides'
    ) {
      return new EmailOverridesNode(props0);
    }

    const node = new EmailOverridesNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3Ecompany})
   */
  company({ args = {}, fallback }: { args?: Rec; fallback: string }): string {
    const props0 = this.getField('company', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3Esandbox})
   */
  sandbox({ args = {}, fallback }: { args?: Rec; fallback: boolean }): boolean {
    const props0 = this.getField('sandbox', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EshopifyDeveloperPermissions})
   */
  shopifyDeveloperPermissions({ args = {} }: { args?: Rec } = {}): ShopifyDeveloperPermissionsNode {
    const props0 = this.getField('shopifyDeveloperPermissions', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'ShopifyDeveloperPermissions'
    ) {
      return new ShopifyDeveloperPermissionsNode(props0);
    }

    const node = new ShopifyDeveloperPermissionsNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * NOTE: Do not remove "integration_tests_user_id_1", it will break integration tests.
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2870/main/draft/logic?selected_field_path=root%3EsendToManualFulfillment})
   */
  sendToManualFulfillment({ args = {}, fallback }: { args?: Rec; fallback: boolean }): boolean {
    const props0 = this.getField('sendToManualFulfillment', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export type Source = {
  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root: Root;
};

const sourceFallback = {
  root: {
    viewCreateOrder: false,
    cityTimezoneMapping: [],
    amazonTrackingPageHTML: false,
    amazonOrderTrackingURLFromEmail: false,
    viewBilling: false,
    developerManagedCommissionAutoEnrolledDevelopers: false,
    discountCodeFlowAutoEnrolledDevelopers: false,
    enableDiscountCodes: false,
    amazonStoreErrors: {
      error020013: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error003052: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error010003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error001001: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error015005: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error008007: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error007003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error015003: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error007007: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
      error008004: { code: 'NO_SELLER_OPTIONS_FULFILLED_BY_AMAZON', reason: '' },
    },
    amazonErrorCodes: {
      error008007: '',
      error007003: '',
      error015003: '',
      error007007: '',
      error008004: '',
    },
    blacklistedOrderErrorReasons: [],
    sellAnythingProductWebhooks: [],
    emailOverrides: { overrides: [] },
    company: '',
    sandbox: false,
    shopifyDeveloperPermissions: { shopifyDeveloperPermissions: [] },
    sendToManualFulfillment: false,
  },
};

export type Rec5 = {
  args: RootArgs;
};

export type Rec4 = {
  root: Rec5;
};

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export class SourceNode extends sdk.Node {
  typeName = 'Query' as const;

  get({ args, fallback = sourceFallback as Source }: { args: Rec4; fallback?: Source }): Source {
    const getQuery = mergeQueryAndArgs(query, args);
    return this.evaluate(getQuery, fallback) as Source;
  }

  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root({ args }: { args: RootArgs }): RootNode {
    const props0 = this.getField('root', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'Root'
    ) {
      return new RootNode(props0);
    }

    const node = new RootNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }
}

export type VariableValues = Rec;
export type DehydratedState = sdk.DehydratedState<Source, VariableValues>;
export type CreateSourceOptions = {
  token: string;
  variableValues?: VariableValues;
  override?: sdk.DeepPartial<Source> | null;
} & sdk.CreateOptions;

export function createSource({
  token,
  variableValues = {},
  override,
  ...options
}: CreateSourceOptions): SourceNode {
  return sdk.create({
    NodeConstructor: SourceNode,
    token,
    query,
    queryCode,
    variableValues,
    override,
    options,
  });
}

export const emptySource = new SourceNode({
  context: null,
  logger: null,
  parent: null,
  step: null,
  expression: null,
});

export function createSourceForServerOnly({
  token,
  variableValues = {},
  override,
  ...options
}: CreateSourceOptions): SourceNode {
  return typeof window === 'undefined'
    ? createSource({ token, variableValues, override, ...options })
    : emptySource;
}

/**
 * @deprecated use createSource instead.
 */
export const initHypertune = createSource;
/**
 * @deprecated use SourceNode instead.
 */
export type QueryNode = SourceNode;
/**
 * @deprecated use Source instead.
 */
export type Query = Source;
