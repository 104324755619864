import useNotifications from 'hooks/useNotifications';

import './style.scss';

const UnreadCounter = () => {
  const { unreadCount } = useNotifications();
  return unreadCount ? <div className="unread-counter">{unreadCount}</div> : null;
};

export default UnreadCounter;
