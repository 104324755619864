import React, { useEffect, useState } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import config from 'config/constants';
import FormRequestBlock from 'components/FormRequestBlock';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  selectUserInfo,
  selectUserJwtValidationKeyLoading,
  updateJwtValidationKey,
} from 'app/store/user';
import { trackSegmentEvent } from 'app/utils';
import { ACTION, SOURCE } from 'app/analytics';
import './styles.scss';

const JWTValidationKeyBlock = () => {
  const { jwtValidationKey, uid } = useAppSelector(selectUserInfo) || {};
  const formLoading = useAppSelector(selectUserJwtValidationKeyLoading);

  const [inputValue, setInputValue] = useState(jwtValidationKey ?? '');
  const [justCopied, setJustCopied] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (jwtValidationKey) {
      setInputValue(jwtValidationKey);
    }
  }, [jwtValidationKey]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(updateJwtValidationKey(inputValue));
    trackSegmentEvent(SOURCE.JWT_MODULE, ACTION.CLICK, 'update_button');
  };

  function handleCopyClick(e: React.SyntheticEvent) {
    e.preventDefault();

    if (uid) {
      navigator.clipboard.writeText(uid);

      // Change the copy icon to a checkmark for ~ 1s
      setJustCopied(true);
      setTimeout(() => setJustCopied(false), 1000);
    }
  }

  return (
    <FormRequestBlock
      title="Add a JWT validation key for your account"
      placeholder="Enter a JWT validation key"
      multiline={true}
      rows={9}
      onSubmit={handleSubmit}
      loading={formLoading}
      required={true}
      inputValue={inputValue}
      onChange={setInputValue}
      text={
        <>
          <p>
            <a href={config.external.doc.jwtDocs} target="_blank" rel="noreferrer">
              Follow this guide to learn how to use your JWT key
            </a>
          </p>

          <div className="copyable-secret">
            <p>Your unique JWT issuer ID: </p>

            <div className="copyable-secret__value" onClick={handleCopyClick}>
              {uid}{' '}
              {justCopied ? (
                <CheckIcon height={16} className="copyable-secret__icon" />
              ) : (
                <CopyIcon height={16} className="copyable-secret__icon" />
              )}
            </div>
          </div>
        </>
      }
    />
  );
};

export default JWTValidationKeyBlock;
